import { IS_ACTIVE, IS_OPEN, STATE } from '../constants'
import { allowScroll, preventScroll } from '../helpers'

export default app => {
  const nav = document.querySelector('.js-products-nav')
  if (!nav) return {}

  const list = nav.querySelector('ul')
  const overlay = document.querySelector('.products__overlay')
  const itemBtns = [...nav.querySelectorAll('li > button')]

  if (!list || !overlay || !itemBtns.length) return {}

  const open = btn => {
    btn.setAttribute(STATE, IS_ACTIVE)
    list.setAttribute(STATE, IS_OPEN)
    overlay.setAttribute(STATE, IS_ACTIVE)
    app.dom.root.classList.add('has-open-products-nav')
    // preventScroll()
  }

  const close = btn => {
    btn.removeAttribute(STATE)
    list.removeAttribute(STATE)
    overlay.removeAttribute(STATE)
    app.dom.root.classList.remove('has-open-products-nav')
    // allowScroll()
  }

  const onClick = e => {
    const isOverlay = e.target === overlay

    if (isOverlay) {
      const btn = document.querySelector('.js-products-nav .products-nav__title')
      close(btn)
      return
    }

    const btn = e.target.closest('.js-products-nav .products-nav__title')
    const itemBtn = e.target.closest('.js-products-nav li > button')

    if (btn) {
      if (btn.getAttribute(STATE) === IS_ACTIVE) {
        close(btn)
      } else {
        open(btn)
      }
    }

    if (itemBtn) {
      itemBtns.forEach(b => b.classList.remove('is-active'))
      itemBtn.classList.add('is-active')

      const opener = document.querySelector('.js-products-nav .products-nav__title')
      opener.innerHTML = itemBtn.textContent
      close(opener)
    }
  }

  return {
    onClick,
  }
}
