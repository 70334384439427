export const {
  STATE,
  IS_ACTIVE,
  IS_OPEN,
  IS_READY,
  IS_LOADED,
  NO_TOUCH,
  HAS_ERROR,
  HAS_TEXT,
  ONLINE,
  OFFLINE,
  DELAYS,
} = {
  STATE: 'data-state',
  IS_ACTIVE: 'active',
  IS_OPEN: 'open',
  IS_READY: 'is-ready',
  IS_LOADED: 'loaded',
  NO_TOUCH: 'no-touch',
  HAS_ERROR: 'has-error',
  HAS_TEXT: 'has-text',
  ONLINE: 'online',
  OFFLINE: 'offline',
  DELAYS: {
    min: 66,
    short: 100,
    medium: 200,
    long: 300,
  },
}
