export default async app => {
  const layout = document.querySelector('.js-animated-layout')
  if (!layout) return

  const { default: anime } = await import(/* webpackChunkName: "animejs" */ 'animejs')

  const headerEls = document.querySelectorAll(
    '.layout__header .header__inner .logo, .layout__header .header__inner .header__controls'
  )

  const heroImgs = document.querySelectorAll('.hero__bg--animated img')
  const heroTitle = document.querySelector('.hero__title')
  const heroText = document.querySelector('.hero__text')

  const tabs = document.querySelectorAll('.tabs')

  const tl = anime.timeline({
    easing: 'easeOutExpo',
  })

  tl.add({
    targets: heroImgs[0],
    translateX: [app.DIRECTION === 'rtl' ? '-150%' : '150%', '0%'],
    rotate: [10, 0],
    duration: 3000,
  })
    .add(
      {
        targets: heroImgs[1],
        translateX: [app.DIRECTION === 'rtl' ? '-150%' : '150%', '0%'],
        rotate: [10, 0],
        duration: 3000,
      },
      0
    )
    .add(
      {
        targets: heroImgs[2],
        translateX: [app.DIRECTION === 'rtl' ? '-150%' : '150%', '0%'],
        rotate: [10, 0],
        duration: 3000,
      },
      0
    )
    .add(
      {
        targets: heroTitle,
        translateY: [100, 0],
        opacity: [0, 1],
        duration: 2000,
      },
      '-=2000'
    )
    .add(
      {
        targets: heroText,
        translateY: [100, 0],
        opacity: [0, 1],
        duration: 2000,
      },
      '-=1950'
    )
    .add(
      {
        targets: tabs,
        translateY: [100, 0],
        opacity: [0, 1],
        duration: 2000,
      },
      '-=1900'
    )
    .add(
      {
        targets: headerEls,
        opacity: [0, 1],
        duration: 2000,
        delay: anime.stagger(100),
      },
      '-=1900'
    )
}
