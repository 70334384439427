import { IS_OPEN, STATE } from '../constants'
import { preventScroll, allowScroll } from '../helpers'

export default () => {
  const close = modal => {
    modal.removeAttribute(STATE)
    allowScroll()
  }

  const open = modal => {
    modal.setAttribute(STATE, IS_OPEN)
    preventScroll()
  }

  const onClick = e => {
    const btn = e.target.closest('.js-country-select-btn')
    const closeBtn = e.target.closest('.js-country-modal')

    if (btn) {
      const modal = document.querySelector('.js-country-modal')
      if (!modal) return

      open(modal)
    }

    if (closeBtn) {
      const modal = document.querySelector('.js-country-modal')
      if (!modal) return

      close(modal)
    }
  }

  return {
    onClick,
  }
}
