import { IS_ACTIVE, STATE } from '../constants'
import { breakpoints } from '../helpers'

export default () => {
  const tabs = document.querySelector('.js-brands-slider')
  const accordion = document.querySelector('.js-brands-accordion')

  if (!tabs || !accordion) return {}

  const tabsBtns = [...document.querySelectorAll('.js-brands-slider-btn')]
  const tabsItems = [...document.querySelectorAll('.js-brands-slider-item')]

  const accordionBtns = [...document.querySelectorAll('.js-brands-accordion-btn')]
  const accoridonItems = [...document.querySelectorAll('.js-brands-accordion-item')]

  if (!tabsItems.length || !tabsBtns.length) return {}
  if (!accordionBtns.length || !accoridonItems.length) return {}

  const tabsItemsWrap = tabs.querySelector('.brands-slider__body')

  if (!tabsItemsWrap) return {}

  const activeTabsItem = () =>
    tabsItemsWrap.querySelector('.js-brands-slider-item[data-state="active"]')

  const setSliderHeight = () => {
    tabsItemsWrap.style.height = `${activeTabsItem().offsetHeight}px`
  }

  const toggleTabs = btn => {
    const id = btn.dataset.target
    const item = tabs.querySelector(`.js-brands-slider-item[data-item="${id}"]`)

    tabsBtns.forEach(b => b.removeAttribute(STATE))
    tabsItems.forEach(it => it.removeAttribute(STATE))

    btn.setAttribute(STATE, IS_ACTIVE)
    item.setAttribute(STATE, IS_ACTIVE)

    setSliderHeight()
  }

  const toggleAccordion = btn => {
    const item = btn.nextElementSibling

    if (btn.getAttribute(STATE) === IS_ACTIVE) {
      btn.removeAttribute(STATE)
      item.removeAttribute(STATE)
      item.style.maxHeight = ''
    } else {
      accordionBtns.forEach(b => b.removeAttribute(STATE))
      accoridonItems.forEach(it => {
        it.removeAttribute(STATE)
        it.style.maxHeight = ''
      })

      btn.setAttribute(STATE, IS_ACTIVE)
      item.setAttribute(STATE, IS_ACTIVE)
      item.style.maxHeight = `${item.scrollHeight}px`
    }
  }

  const onClick = e => {
    const tabsBtn = e.target.closest('.js-brands-slider-btn')
    const accordionBtn = e.target.closest('.js-brands-accordion-btn')

    if (tabsBtn) toggleTabs(tabsBtn)
    if (accordionBtn) toggleAccordion(accordionBtn)
  }

  const onResize = () => {
    if (!breakpoints.sm.matches) return
    setSliderHeight()
  }

  const init = () => {
    setSliderHeight()
  }

  return {
    init,
    onClick,
    onResize,
  }
}
