import './public-path'
import { isModernBrowser } from './helpers'

import loadPolyfills from './polyfills/loadPolyfills'
import setHTMLClassNames from './methods/setHTMLClassNames'

import setLazy from './components/LazyLoader/setLazy'
import Offline from './components/Offline/Offline'
import handleCountriesModal from './methods/handleCountriesModal'
import handleSearch from './methods/handleSearch'
import handleTruncate from './methods/handleTruncate'
import handleProductsNav from './methods/handleProductsNav'
import handleBrandsSlider from './methods/handleBrandsSlider'
import handleBaseTabs from './methods/handleBaseTabs'
import animateStepOne from './methods/animateStepOne'

class App {
  constructor() {
    this.dom = {
      root: document.documentElement,
      body: document.body,
    }
    this.LANGUAGE = this.dom.root.getAttribute('lang')?.split('-')[0].toLowerCase() || 'en'
    this.DIRECTION = this.dom.root.getAttribute('dir') || 'ltr'
    this.state = {}

    this.offline = new Offline(this)
    this.countriesModal = handleCountriesModal()
    this.search = handleSearch()
    this.productsNav = handleProductsNav(this)
    this.brandsSlider = handleBrandsSlider(this)
  }

  updateState(state) {
    this.state = {
      ...this.state,
      ...state,
    }
  }

  initMethods() {
    setHTMLClassNames(this)
    setLazy(this)
    handleTruncate()
    handleBaseTabs()
    animateStepOne(this)
  }

  onOfflineHandler = () => {
    this.offline.onOffline()
  }

  onOnlineHandler = () => {
    this.offline.onOnline()
  }

  onClickHandler = e => {
    this.countriesModal.onClick(e)
    this.productsNav.onClick?.(e)
    this.brandsSlider.onClick?.(e)
    this.search.onClick?.(e)
  }

  onKeyupHandler = e => {}

  onFocusHandler = e => {
    this.search.onFocus?.(e)
  }

  onBlurHandler = e => {
    this.search.onBlur?.(e)
  }

  onInputHandler = e => {
    this.search.onInput?.(e)
  }

  onChangeHandler = e => {}

  onResizeHandler = e => {
    this.brandsSlider.onResize?.(e)
  }

  addListeners() {
    document.addEventListener('click', this.onClickHandler)
    document.addEventListener('keyup', this.onKeyupHandler)
    document.addEventListener('focus', this.onFocusHandler, true)
    document.addEventListener('blur', this.onBlurHandler, true)
    document.addEventListener('input', this.onInputHandler, true)
    document.addEventListener('change', this.onChangeHandler, true)
    window.addEventListener('resize', this.onResizeHandler)
    window.addEventListener('offline', this.onOfflineHandler)
    window.addEventListener('online', this.onOnlineHandler)
  }

  init() {
    this.initMethods()
    this.addListeners()

    this.offline.init()
    this.brandsSlider.init?.()
  }
}

const init = () => {
  const app = new App()
  app.init()
}

if (isModernBrowser) {
  document.addEventListener('DOMContentLoaded', init)
} else {
  document.addEventListener('DOMContentLoaded', loadPolyfills(init))
}
