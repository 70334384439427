import { breakpoints } from '../helpers'

export default () => {
  if (breakpoints.sm.matches) return

  const tabs = document.querySelector('.js-base-tabs')
  if (!tabs) return

  const list = tabs.querySelector('ol')
  const listWrap = tabs.querySelector('.tabs__tabs')

  if (!list || !listWrap) return

  const isOverflowing = list.offsetWidth > tabs.offsetWidth

  if (!isOverflowing) return

  const activeItem = tabs.querySelector('.tabs__btn-item--active')

  if (!activeItem) return
  const paddingLeft = parseInt(
    window.getComputedStyle(activeItem.querySelector('.tabs__btn'))?.paddingLeft,
    10
  )

  const left = paddingLeft ? activeItem.offsetLeft - paddingLeft : activeItem.offsetLeft

  listWrap.scrollLeft = left
}
