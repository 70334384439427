import { HAS_ERROR, HAS_TEXT, IS_OPEN, STATE } from '../constants'

const LIST_TRANSITION_DURATION = 300

const List = ({ list, emptyMessage = '' }) =>
  list.length > 0
    ? `
      <ul>
        ${list
          .map(
            ({ value }) => `<li>
          <button type="button">${value}</button>
        </li>`
          )
          .join('')}
      </ul>
      `
    : `<ul>
        <li class="search__empty-message">${emptyMessage}</li>
      </ul>`

export default () => {
  const search = document.querySelector('.js-search')

  if (!search) return {}

  const list = search.querySelector('.search__list-wrap')
  const listObj = [...list.querySelectorAll('li')].map(li => ({ value: li.textContent.trim() }))
  const emptyMessage = search.querySelector('.search__empty-message')

  const onClearBtnClick = () => {
    const input = search.querySelector('input')

    input.value = ''
    search.removeAttribute(STATE)

    setTimeout(() => {
      list.innerHTML = List({ list: listObj })
    }, LIST_TRANSITION_DURATION)
  }

  const filterList = inpuValue => {
    const filteredList = listObj.filter(({ value }) =>
      value.toUpperCase().includes(inpuValue.toUpperCase())
    )

    if (filteredList.length === 0) {
      search.setAttribute(STATE, HAS_ERROR)
    }

    list.innerHTML = List({ list: filteredList, emptyMessage: emptyMessage.innerHTML })
  }

  const onFocus = e => {
    if (!list) return
    const input = e.target.closest('.js-search input')
    if (!input) return

    list.setAttribute(STATE, IS_OPEN)
  }

  const onBlur = e => {
    if (!list) return
    const input = e.target.closest('.js-search input')
    if (!input) return

    list.removeAttribute(STATE)
  }

  const onInput = e => {
    const input = e.target.closest('.js-search input')
    if (!input) return

    if (input.value.trim() === '') {
      search.removeAttribute(STATE)
    } else {
      search.setAttribute(STATE, HAS_TEXT)
    }

    filterList(input.value)
  }

  const onClick = e => {
    const clearBtn = e.target.closest('.js-search .search__clear')

    if (clearBtn) {
      onClearBtnClick()
    }
  }

  return {
    onFocus,
    onBlur,
    onInput,
    onClick,
  }
}
