import { IS_OPEN, STATE } from '../constants'
import Ellipsity from '../lib/Ellipsity'

export default () => {
  const handleContentText = () => {
    const blocks = [...document.querySelectorAll('.js-read-more-text')]
    if (!blocks.length) return

    blocks.forEach(block => {
      const btn = block.querySelector('.read-more-btn')
      if (!btn) return

      btn.addEventListener('click', () => {
        block.setAttribute(STATE, IS_OPEN)
      })
    })
  }

  const handleInlineText = () => {
    const blocks = [...document.querySelectorAll('.js-truncate')]
    if (!blocks.length) return

    blocks.forEach(block => {
      const textWrap = block.querySelector('.js-truncate-text')
      const showBtn = block.querySelector('.js-truncate-show-btn')

      const ellipsity = new Ellipsity({
        charachters: 250,
        container: textWrap,
        showBtn,
      })
      ellipsity.init()
    })
  }

  handleContentText()
  handleInlineText()
}
